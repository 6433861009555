import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logos/logo-horizontal.svg";
import blueLogo from "../../images/logos/logo-horizontal.svg";
import menuIcon from "../../images/logos/mobileMenu.svg";
import closeIcon from "../../images/icons/close.svg";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

const StlButton = styled(Button)`
  background: none !important;
  color: #e0fc01 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 0 !important;
  margin-top: -6px !important;
  &:hover {
    text-decoration: underline !important;
    color: #02b5d5 !important;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 20px !important;
  }
`;

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));
  const [navOpen, setNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {}, [navOpen]);

  const renderShopButton = () => {
    return (
      <>
        <StlButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className="shop-dropdown"
          variant="contained"
          style={{ marginLeft: "20px" }}
          disableElevation={true}
        >
          Shop
        </StlButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          color="secondary"
        >
          <MenuItem onClick={handleClose}>
            <Link to="/shop">Buy Online</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/find-us">Store Locator</Link>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <header id="header" className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="wrapper no-max-width">
        <Link
          to="/"
          className={`logo-container ${navOpen ? "hide" : undefined}`}
        >
          <img id="logo" className="logo" src={logo} alt="Brand Logo" />
        </Link>
        {!navOpen && (
          <div
            className={`nav-menu ${navOpen ? "close" : "open"}`}
            onClick={toggleNav}
          >
            <img
              className="mobile-logo"
              src={menuIcon}
              alt="Mobile Brand Logo"
            />
          </div>
        )}

        <nav id="navigation-container" className={navOpen ? "open" : undefined}>
          <div
            className={`nav-menu open-mobile-nav hide-desktop ${
              navOpen ? "close" : "open"
            }`}
            onClick={toggleNav}
          >
            <img src={closeIcon} alt="Close Menu Button" />
          </div>
          <Link className="hide-desktop" to="/">
            <img
              className="mobile-logo open-drawer"
              src={blueLogo}
              alt="Mobile Brand Logo"
            />
          </Link>

          {/* 
          <Link
            onClick={() => {
              setNavOpen(false);
            }}
            to="/products"
            className={props.activePage === "/products" ? "active" : undefined}
          >
            Products
          </Link> */}
          {/* <Link
            onClick={() => {
              setNavOpen(false);
            }}
            to="/social-mindsets"
            className={
              props.activePage === "/social-mindsets" ? "active" : undefined
            }
          >
            Social Mindsets
          </Link> */}
          {/* <Link
            onClick={() => {
              setNavOpen(false);
            }}
            to="/about"
            className={props.activePage === "/about" ? "active" : undefined}
          >
            About
          </Link> */}
          {isMobile ? (
            <>
              <Link
                onClick={() => {
                  setNavOpen(false);
                }}
                to={"/shop"}
                className={props.activePage === "/shop" ? "active" : undefined}
              >
                Buy Online
              </Link>
              <Link
                onClick={() => {
                  setNavOpen(false);
                }}
                to={"/find-us"}
                className={props.activePage === "/find-us" ? "active" : null}
              >
                Store Locator
              </Link>
            </>
          ) : (
            renderShopButton()
          )}
        </nav>
      </div>
    </header>
  );
}
